import { Box, lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { VFC } from "react";
import { ReclaimEdition } from "../../reclaim-api/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    color: theme.palette.text.primary,
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
  },
  paid: {
    backgroundColor: theme.colors.logo.corn,
  },
  medium: {},
  small: {},
  slim: {},
}));

export type EditionBadgeProps = {
  edition: ReclaimEdition;
  className?: string;
  size?: "slim" | "small" | "medium";
};

export const EditionBadge: VFC<EditionBadgeProps> = ({ edition, className, size = "medium" }) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      className={clsx(classes.root, className, {
        [classes.paid]: edition !== ReclaimEdition.Free,
        [classes.medium]: size === "medium",
        [classes.small]: size === "small",
        [classes.slim]: size === "slim",
      })}
    >
      {edition.label}
    </Box>
  );
};
